// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Vorlauftemperatur der Wärmepumpe"
        meta={[
          { name: 'description', content: 'Vorlauftemperatur für Ihre Wärmepumpe ➤ Vorlauftemperatur &amp; Heizkurve erklärt ✔️ Temperatur richtig einstellen ✔️ Hier informieren!' },
          { name: 'keywords', content: 'vorlauftemperatur wärmepumpe, wärmepumpe vorlauftemperatur' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='32EHWAB322UtqL7l6RoViY-23a7tFh3fOFRYVype608QC'
          id='die-optimale-vorlauftemperatur-waermepumpe-richtig-einstellen'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "23a7tFh3fOFRYVype608QC",
    "updatedAt": "2024-03-04T14:55:16.730Z",
    "title": "Die optimale Vorlauftemperatur: Wärmepumpe richtig einstellen",
    "anchorName": "die-optimale-vorlauftemperatur-waermepumpe-richtig-einstellen",
    "text": "<ul>\n<li><a href=\"https://heizungsmacher.ch/wissen/die-optimale-vorlauftemperatur-waermepumpe-richtig-einstellen#was-ist-die-vorlauftemperatur\">Was ist die Vorlauftemperatur?</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/die-optimale-vorlauftemperatur-waermepumpe-richtig-einstellen/#vorlauftemperaturen-unterschiedlicher-heizsysteme\">Vorlauftemperaturen unterschiedlicher Heizsysteme</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/die-optimale-vorlauftemperatur-waermepumpe-richtig-einstellen/#bei-welcher-vorlauftemperatur-ist-eine-waermepumpe-effizient-und-sinnvoll\">Bei welcher Vorlauftemperatur ist eine Wärmepumpe effizient und sinnvoll?</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/die-optimale-vorlauftemperatur-waermepumpe-richtig-einstellen/#vorlauftemperatur-richtig-einstellen\">Vorlauftemperatur richtig einstellen</a></li>\n<li><a href=\"https://www.heizungsmacher.ch/wissen/die-optimale-vorlauftemperatur-waermepumpe-richtig-einstellen/#wuenschen-sie-eine-beratung\">Wünschen Sie eine Beratung?</a></li>\n</ul>\n<p><strong>Damit eine Heizung effizient und sinnvoll arbeiten kann, muss die Vorlauftemperatur richtig eingestellt sein</strong>. Das gilt aber nicht nur für Heizkörper, sondern auch für <a href=\"https://www.heizungsmacher.ch/wissen/fussbodenheizung-einstellung-aufbau-kombination-mit-waermepumpe/\">Fussbodenheizungen</a> und <a href=\"https://www.heizungsmacher.ch/waermepumpen/\">Wärmepumpen</a>. In diesem Artikel erfahren Sie, was die Vorlauftemperatur ist und wie Sie sie für eine Wärmepumpe korrekt einstellen.</p>\n",
    "image": {
        "description": "Eine Grafik, auf der die Vorlauftemperatur aufgezeigt wird",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/17EGODLCxVf9swwfYdVd3K/72b76c1fbc016cf10481366cda6ab31c/2302---Grafik-Vorlauftemperatur.png"
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='32EHWAB322UtqL7l6RoViY-bUq5mfaa4wmG3uZKPeQ5N'
          id='was-ist-die-vorlauftemperatur'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "bUq5mfaa4wmG3uZKPeQ5N",
    "updatedAt": "2023-02-09T14:10:44.129Z",
    "title": "Was ist die Vorlauftemperatur?",
    "anchorName": "was-ist-die-vorlauftemperatur",
    "text": "<p><strong>Die Vorlauftemperatur ist die Temperatur des Heizwassers, welches dem Heizkörper (Radiator) oder der Fussbodenheizung zugeführt wird, damit im Raum die gewünschte Raumtemperatur erreicht wird</strong> – ganz unabhängig von den Aussentemperaturen. Dabei spielt auch die Temperaturdifferenz zwischen Vor- und Rücklauftemperatur eine grosse Rolle. Die Rücklauftemperatur ist die Temperatur des Wassers, nachdem es seine Wärme abgegeben hat und auf dem Weg zurück zur Wärmepumpe ist. <strong>Je tiefer die Vorlauftemperatur ist, desto effizienter arbeitet die Wärmepumpe</strong>.</p>\n<p>Manuelle Regelungen der Vorlauftemperatur sind nur noch ganz selten bei älteren Ölheizungen vorhanden. Bei modernen Wärmepumpen wird die Vorlauftemperatur von der Heizkurve gesteuert.</p>\n<h3>Was ist die Heizkurve?</h3>\n<p>Eine Heizkurve, auch Heizkennlinie genannt, definiert den individuellen Zusammenhang zwischen der Aussentemperatur, der gewünschten Raumtemperatur und der dafür nötigen Vorlauftemperatur. <strong>Wird es also draussen um 1 °C wärmer oder kälter, so passt sich die Vorlauftemperatur dank der eingestellten Heizkurve an. Mit einer optimierten, bedarfsorientierten Heizkurve können Sie sicherstellen, dass die Vorlauftemperaturen richtig eingestellt sind und die Geräte maximal effizient arbeiten können</strong>.</p>\n<p><img src=\"//images.ctfassets.net/wbm1kpreb3m8/5wEXEjSPHgCcj1aAxSPzLz/22f407ee54bca11adf96c0ac34c0603e/2302_-_Grafik_Heizkurve_SVG.svg\" alt=\"Eine Grafik welche die Heizkurve aufzeigt\"></p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='32EHWAB322UtqL7l6RoViY-U66xW2rhT7WQRcOkLcECg'
          id='vorlauftemperaturen-unterschiedlicher-heizsysteme'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "U66xW2rhT7WQRcOkLcECg",
    "updatedAt": "2023-02-17T10:14:47.312Z",
    "title": "Vorlauftemperaturen unterschiedlicher Heizsysteme",
    "anchorName": "vorlauftemperaturen-unterschiedlicher-heizsysteme",
    "text": "<p>Die benötigten Vorlauftemperaturen werden grundsätzlich von <strong>zwei Faktoren</strong> beeinflusst: dem <strong>Heizverteilsystem</strong> (Heizkörper oder Fussbodenheizung) und der <strong>Dämmung des Gebäudes</strong>.</p>\n<p><strong>Wenn Sie beim Steuern Ihrer Heizung Wert auf hohe Energieeffizienz legen, sollten Sie die Vorlauftemperatur möglichst niedrig einstellen</strong>. Denn das sorgt bei vielen Wärmeerzeugern für einen sparsamen Betrieb. Gerade Wärmepumpen profitieren von niedrigen Vorlauftemperaturen.</p>\n<table>\n<thead>\n<tr>\n<th>Heizsystem</th>\n<th>Maximale Vorlauftemperatur</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Sehr alte, kleine Heizkörper und schlechte Dämmung (nicht für Wärmepumpen geeignet)</td>\n<td>65 °C</td>\n</tr>\n<tr>\n<td>Normale Heizwände oder Radiatoren (typisch in der Schweiz)</td>\n<td>50 °C bis max. 55 °C</td>\n</tr>\n<tr>\n<td>Moderne Heizkörper, ausgelegt für Niedertemperatur</td>\n<td>45 °C</td>\n</tr>\n<tr>\n<td>Fussbodenheizung</td>\n<td>35 °C bis max. 40 °C</td>\n</tr>\n</tbody>\n</table>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='32EHWAB322UtqL7l6RoViY-3JZO0hERHVajZN1hbzxzgz'
          id='bei-welcher-vorlauftemperatur-ist-eine-waermepumpe-effizient-und-sinnvoll'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "3JZO0hERHVajZN1hbzxzgz",
    "updatedAt": "2023-02-17T10:17:02.345Z",
    "title": "Bei welcher Vorlauftemperatur ist eine Wärmepumpe effizient und sinnvoll?",
    "anchorName": "bei-welcher-vorlauftemperatur-ist-eine-waermepumpe-effizient-und-sinnvoll",
    "text": "<p>Eine Wärmepumpe kann bei verschiedenen Vorlauftemperaturen betrieben werden, je nachdem welche Temperatur für das jeweilige System am besten geeignet ist. <strong>Die meisten Wärmepumpen lassen sich bis ca. 50-55 °C relativ effizient betreiben</strong>. Gewisse Wärmepumpen, <strong>besonders solche mit Propan als Kältemittel, ermöglichen Vorlauftemperaturen von bis zu 70 °C</strong>.\n<img src=\"//images.ctfassets.net/wbm1kpreb3m8/uVu648QGMJyYy8SppVRyV/a7432b6ceb4ba95482bafd0d41112c35/3---Bild-Einleitung.png\" alt=\"Glühbirne - Experten-Tipp\"></p>\n<h3><strong>Expertentipp:</strong> Ist meine Heizung trotz hoher Vorlauftemperatur für eine Wärmepumpe geeignet?</h3>\n<p>Wenn Sie unsicher sind, ob die Vorlauftemperatur Ihrer Heizkörper für eine Wärmepumpe geeignet ist, können Sie einen kleinen Test durchführen: Berühren Sie bei maximalem Betrieb (tiefe Aussentemperaturen) die Radiatoren – wenn Sie diese dann ohne Verbrennungsgefahr anfassen können, ist die Vorlauftemperatur Ihrer Heizkörper in der Regel für eine Wärmepumpe geeignet.</p>\n<p><strong>Wichtig zu wissen</strong>: <strong>Je höher die Temperatur der Radiatoren - desto schlechter der <a href=\"https://www.heizungsmacher.ch/wissen/wie-funktioniert-eine-waermepumpe/#cop-kennzahl-fuer-die-leistungsfaehigkeit-der-waermepumpe\">COP Wert</a></strong>. Faustregel: benötigt ein Radiator bei 0°C Aussentemperatur über 55°C Vorlauftemperatur, sind vorgängige Isolationsmassnahmen des Gebäudes empfehlenswert.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='32EHWAB322UtqL7l6RoViY-3jlhjKKSoOYF8GK4KCEYcp'
          id='vorlauftemperatur-richtig-einstellen'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "3jlhjKKSoOYF8GK4KCEYcp",
    "updatedAt": "2023-02-17T10:16:06.437Z",
    "title": "Vorlauftemperatur richtig einstellen",
    "anchorName": "vorlauftemperatur-richtig-einstellen",
    "text": "<p><img src=\"//images.ctfassets.net/wbm1kpreb3m8/6AXgaoZpIYtrA5SBtQ5K5n/b2a89b03f94cd19ba954e82288b8842a/2211---Lebensdauer-WP---Handwerker-Figur.png\" alt=\"Eine Figur, welche einen Handwerker darstellt, der eine Wärmepumpe wartet und somit die Lebensdauer einer Wärmepumpe erhöht\">Die Einstellung der richtigen Temperaturen (<strong>Heizkurve</strong>) und eine gleichmässige Wärmeabgabe im Heizverteilsystem (<strong>hydraulischer Abgleich)</strong> sind der Schlüssel für einen komfortablen und effizienten Betrieb der Wärmepumpe. <strong>Eine zu hohe Temperatur kann zu einem ineffizienten System führen, das mehr Energie als nötig verbraucht</strong> und möglicherweise nicht für eine passende Beheizung Ihres Hauses sorgt. <strong>Am besten lassen Sie sich von einer <a href=\"https://www.heizungsmacher.ch/ueber-uns/\">Fachperson</a> beraten</strong>, um die optimalen Vorlauftemperaturen zu ermitteln. Wenn Sie hier <strong>von Anfang an auf die richtige Einstellung achten, können Sie später viel Zeit, Geld und Mühe sparen</strong>!</p>\n<p><strong>Wir - die Heizungsmacher helfen Ihnen dabei, die richtigen Einstellungen für Ihr Haus und Ihre Wärmepumpe festzulegen. Das geschieht ganz komfortabel via Online-Fernzugriff, welcher bei allen unseren Wärmepumpen möglich ist</strong>.</p>\n"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='32EHWAB322UtqL7l6RoViY-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
